<script setup lang="ts">
import { useInfiniteQuery, useQuery } from '@tanstack/vue-query'
import { BlogItems, SearchInput } from './components'
import InfiniteQuery from './components/InfiniteQuery.vue'

const { locale } = useI18n()
const router = useRouter()
const categoryAlias = toRef(() => {
  const alias = (router.currentRoute.value.params?.category ?? '') as string
  return decodeURIComponent(alias)
})

const categoryQuerier = useQuery({
  staleTime: Number.POSITIVE_INFINITY,
  refetchOnWindowFocus: false,
  enabled: !import.meta.env.SSR,
  queryKey: [apisBlog.apiNewsCategory.id, locale],
  queryFn: () => apisBlog.apiNewsCategory(undefined),
  select(data) {
    return [
      {
        mid: 0,
        name: $t('9lZ08iwCgBjsEp5Hb1R'),
        alias: '',
      },
      ...data ?? [],
    ]
  },
})
const newsQuerier = useInfiniteQuery({
  refetchOnWindowFocus: false,
  enabled: !import.meta.env.SSR,
  queryKey: [apisBlog.apiNewsList.id, locale, categoryAlias],
  queryFn: ({ pageParam }) => apisBlog.apiNewsList({ page_size: 15, alias: categoryAlias.value, ...pageParam }),
})

onMounted(() => {
  document.querySelector('.catgory-nav .active')?.scrollIntoView()
})

const i18nHead = useLocaleHead({ addSeoAttributes: true })
useHead({
  title: () => {
    const category = categoryAlias.value || $t('9lZ08iwCgBjsEp5Hb1R')
    const categorys = categoryQuerier.data.value?.map(item => item.alias || item.name) ?? []
    const title = categorys.includes(category) ? `${$t('1J6d94jQiyf2iQ5ynjsR')} - ${category}` : $t('1J6d94jQiyf2iQ5ynjsR')

    return title
  },
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [
    ...(i18nHead.value.meta || []),
  ],
})
</script>

<template>
  <div class="page-content-width new-list-page">
    <NuxtLinkLocale class="hidden" to="/blog/catgory/blog-0" data-test-id="CAil" />
    <NuxtLinkLocale class="hidden" to="/blog/catgory" data-test-id="tDDD" />

    <div v-if="categoryQuerier.data.value" class="header">
      <nav class="catgory-nav">
        <ul>
          <template v-for="item of categoryQuerier.data.value" :key="item.mid">
            <li v-if="item.alias" :class="{ active: item.alias === categoryAlias }" :data-alias="categoryAlias">
              <NuxtLink :to="decodeURIComponent(localePath(`/blog/${item.alias}`))">{{ item.name }}</NuxtLink>
            </li>
            <li v-else :class="{ active: item.alias === categoryAlias }">
              <NuxtLinkLocale to="/blog" data-test-id="1wZI">{{ item.name }}</NuxtLinkLocale>
            </li>
          </template>
        </ul>
      </nav>

      <SearchInput class="search" />
    </div>

    <InfiniteQuery class="new-list-wrap" :queryier="newsQuerier" #="{ record }">
      <BlogItems.Style1
        :id="record.news_id"
        :key="record.news_id"
        :title="record.title"
        :alias="record.alias"
        :cover="record.covers"
        :summary="record.summary"
        :time="record.create_time"
        :category="record.category?.[0].alias ?? ''"
      />
    </InfiniteQuery>
  </div>
</template>

<style scoped lang="scss">
@mixin active{
  color: #fff;
  background: linear-gradient(#266EF1, #2A47AB);
}

.new-list-page {
  margin: 15px auto 20px;

  .header {
    display: grid;
    grid-template-areas: 'nav search';
    grid-template-columns: 1fr auto;
    margin: 40px 0;

    @media bp-lt-tablet {
      grid-template-areas: 'search' 'nav';
      grid-template-columns: 1fr;
      gap: 10px;
      margin: 0 0 15px;
    }
  }
}

.catgory-nav {
  position: relative;
  flex: 1;
  grid-area: nav;
  width: 100%;
  height: 50px;
  margin-right: 1em;
  overflow-x: auto;

  @media bp-lt-tablet {
    height: 40px;
  }
}

.catgory-nav ul {
  position: absolute;
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: auto hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media bp-lt-tablet {
    position: static;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    margin-right: 12px;
    color: #8098bf;
    background: linear-gradient( #2C3967, #202C52);
    border-radius: 15px;

    @media bp-lt-tablet {
      margin-right: 10px;
    }

    @media (pointer: fine) {
      /* 非触摸屏设备的样式 */
      &:hover{
        @include active;
      }
    }

    &:active {
      transform: scale(0.95);
    }
  }

  a {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 130px;
    height: 100%;
    padding: 0 1em;
    font-size: 1.2rem;
    line-height: 1.5em;
    color: inherit;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
  }

  .active {
    // background: linear-gradient(#266EF1, #2A47AB);
    // color: #fff;
    @include active;
  }
}

.search {
  grid-area: search;
  margin-left: 15px;

  @media bp-lt-tablet {
    margin-left: 0;
  }
}

.new-list-wrap :deep(.list){
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 26px;

  @media bp-lt-tablet {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
</style>
